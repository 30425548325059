<template>
  <div class="template-profile-dropdown">
    <b-dropdown variant="link" toggle-class="text-decoration-none">
      <template #button-content>
        <div class="profile-img" v-if="!$user.user || !$user.user.user_photo">
          <i
            class="fa-solid fa-user"
            :class="lightTheme ? 'text-secondary' : 'text-white'"
          />
        </div>
        <img
          :src="$user.user.user_photo"
          alt="user"
          v-else
          class="profile-img"
        />
      </template>

      <theme-toggle />

      <b-dropdown-divider class="my-3" />

      <b-dropdown-item :to="{ name: 'account' }">
        <i class="fa-solid fa-user" />
        <span>
          Minha conta
        </span>
      </b-dropdown-item>

      <!-- <b-dropdown-item :to="{ name: 'notes' }">
        <i class="fa-regular fa-note-sticky" />
        <span>
          Minhas anotações
        </span>
      </b-dropdown-item>

      <b-dropdown-item :to="{ name: 'certificates' }">
        <certificate-icon :light="lightTheme" />
        <span>
          Certificados
        </span>
      </b-dropdown-item> -->

      <b-dropdown-item-button @click="$emit('logout')" class="mt-3">
        <i class="fa-solid fa-power-off text-danger" />
        <span class="text-danger">
          Sair
        </span>
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import ThemeToggle from "./ThemeToggle";
// import CertificateIcon from "@/assets/vue-icons/CertificateIcon";
import { mapState } from "vuex";
export default {
  components: {
    ThemeToggle
  },
  computed: {
    ...mapState(["theme"]),
    lightTheme() {
      return this.theme === "light";
    }
  }
};
</script>

<style lang="scss">
.template-profile-dropdown {
  .profile-img {
    width: 36px;
    display: flex;
    aspect-ratio: 1;
    font-size: 18px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-divider {
    opacity: 0.4;
  }

  .dropdown-menu {
    min-width: 240px;
    font-size: 14px;
    border-radius: 8px;
    padding: 24px 16px;
    left: -32px !important;
    backdrop-filter: blur(40px);

    .dropdown-item {
      gap: 8px;
      display: grid;
      padding: 4px 16px;
      border-radius: 4px;
      align-items: center;
      grid-template-columns: 24px 1fr;

      i {
        display: flex;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}
</style>
