<template>
  <span class="loading">
    <span v-if="!show">
      <slot />
    </span>
    <b-spinner v-else />
  </span>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  i {
    display: inline-block;
    animation: rotating 2s linear infinite;
  }
}
</style>
