export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setTheme(state, theme) {
    state.theme = theme;
  },
  setMyCourses(state, myCourses) {
    state.myCourses = myCourses;
  }
};
