import { setToken } from "../../setup/api";

export default {
  login(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/signin", data)
        .then(function(response) {
          setToken(response.data.token);
          context.commit("setLogged", true);
          resolve();
        })
        .catch(reject);
    });
  },

  register(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/", data)
        .then(function(response) {
          setToken(response.data.token);
          context.commit("setLogged", true);
          resolve();
        })
        .catch(reject);
    });
  },

  forgot(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/forgot", data)
        .then(resolve)
        .catch(reject);
    });
  },

  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/reset-password", data)
        .then(resolve)
        .catch(reject);
    });
  },

  get(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/accounts/")
        .then(function(response) {
          context.commit("setUser", response.data);
          context.commit("setTheme", response.data?.theme, { root: true });
          resolve();
        })
        .catch(reject);
    });
  },

  update(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/accounts/", data)
        .then(function() {
          context.commit("updateUser", data);
          resolve();
        })
        .catch(reject);
    });
  },

  updatePassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/accounts/change-password", data)
        .then(resolve)
        .catch(reject);
    });
  }
};
