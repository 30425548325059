<template>
  <footer
    class="templates-app-footer w-100"
    :class="{ 'dark-theme': darkTheme }"
  >
    <div
      class="container-fluid d-flex flex-column flex-md-row align-items-center"
    >
      <div
        class="d-flex flex-column justify-content-start mb-5 mb-md-0 pt-5 pt-md-0"
      >
        <div :class="`${logo} mx-auto mx-md-0`" />
        <div class="d-lg-flex justify-content-center">
          <div class="warning small pt-3">
            ©{{ year }} – Blitzpay | Todos os Direitos Reservados
          </div>
          <!-- <div class="small pt-3">
            <a class="pr-4" href="#">Termos de uso</a>
            <a href="#">Privacidade e cookies</a>
          </div> -->
        </div>
      </div>

      <div class="ml-md-auto">
        <div class="app-buttons pr-md-5">
          Baixe o nosso app
          <button class="btn btn-outline-secondary" @click="appStore">
            <div class="d-flex align-items-center justify-content-center px-5">
              <i class="fa-brands fa-apple mr-2"></i>
              App Store
            </div>
          </button>
          <button class="btn btn-outline-secondary" @click="googlePlay">
            <div class="d-flex align-items-center justify-content-center px-5">
              <i class="fa-brands fa-google-play mr-2"></i>
              Google Play
            </div>
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
    logo() {
      return this.darkTheme ? "logo-dark" : "logo-light";
    },
    darkTheme() {
      return this.$store.state.theme == "dark";
    }
  },
  methods: {
    appStore() {
      window.open(
        "https://apps.apple.com/br/app/blitzpay-%C3%A1rea-de-membros/id6451417807",
        "_blank"
      );
    },
    googlePlay() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.blitzpay.members&hl=pt_BR&gl=US",
        "_blank"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.templates-app-footer {
  padding: 8px 0;
  margin-bottom: 24px;
  background-color: transparent;

  a {
    font-weight: bold;
    text-decoration: none;
  }

  .warning {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .logo-light,
  .logo-dark {
    background-image: url("../../assets/images/logo-dark.svg");
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 40px;
  }

  .logo-light {
    background-image: url("../../assets/images/logo-white.svg");
  }

  .app-buttons {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
  }

  .btn-outline-secondary {
    color: #77788e;
    border-radius: 30px;
    border-color: #77788e;
    &:hover {
      color: white;
    }
  }
  &.dark-theme {
    color: white;
    .btn-outline-secondary {
      border: 1px solid var(--Verso-Dark-Grey-1, #bebfc7);
      color: #bebfc7 !important;
    }
  }
}
</style>
