import Vue from "vue";
import Router from "vue-router";
// import VueGtm from "vue-gtm";

import Home from "./views/Home.vue";
import DefaultTemplate from "./templates/Default.vue";
import Auth from "./templates/Auth.vue";

Vue.use(Router);

const vueRouter = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/minha-conta",
      name: "account",
      component: () => import("./views/Account.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/certificados",
      name: "certificates",
      component: () => import("./views/Certificates.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/anotacoes",
      name: "notes",
      component: () => import("./views/Notes.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: {
        template: Auth,
        protected: false
      }
    },
    {
      path: "/esqueci-a-senha",
      name: "forgot-password",
      component: () => import("./views/ForgotPassword.vue"),
      meta: {
        template: Auth,
        protected: false
      }
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("./views/ResetPassword.vue"),
      meta: {
        template: Auth,
        protected: false
      }
    },
    {
      path: "/termos-de-uso",
      name: "terms",
      component: () => import("./views/Terms.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "/politica-de-privacidade",
      name: "privacy",
      component: () => import("./views/Privacy.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/NotFound.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    }
  ]
});

// Vue.use(VueGtm, {
//   id: "GTM-TB4VDDF",
//   vueRouter
// });

export default vueRouter;
