<template>
  <div class="form-group" :class="{ 'has-icon': !!icon }">
    <label :for="id" v-if="label">{{ label }}</label>
    <i v-if="icon" :class="'icon ' + icon"></i>
    <textarea
      v-if="textarea"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div class="form-wrapper" v-else-if="type === 'password'">
      <input
        v-model="innerValue"
        class="form-control"
        :class="classObject"
        :id="id"
        :type="!showPassword && type"
        :placeholder="placeholder"
        :autocomplete="disableAutocomplete ? 'off' : ''"
        :autocorrect="disableAutocapitalize ? 'off' : ''"
        :autocapitalize="disableAutocapitalize ? 'none' : ''"
        :readonly="readonly"
        :disabled="disabled"
        @blur="blur"
      />
      <div
        :class="
          showError || showInnerError ? 'show-error-icon' : 'show-password-icon'
        "
      >
        <button
          v-if="!(showError || showInnerError)"
          type="button"
          class="clear-button pt-1"
          @click="showPassword = !showPassword"
        >
          <i
            :class="
              !(showPassword || showInnerError) ? 'icon-eye' : 'icon-eye-off'
            "
          />
        </button>
        <div v-else>
          <i class="fa-solid fa-circle-exclamation" />
        </div>
      </div>
    </div>
    <div class="form-wrapper" v-else>
      <input
        v-model="innerValue"
        class="form-control"
        :class="classObject"
        :id="id"
        :type="type"
        :placeholder="placeholder"
        :autocomplete="disableAutocomplete ? 'off' : ''"
        :autocorrect="disableAutocapitalize ? 'off' : ''"
        :autocapitalize="disableAutocapitalize ? 'none' : ''"
        :readonly="readonly"
        :disabled="disabled"
        @blur="blur"
      />
      <div v-if="showError || showInnerError" class="show-error-icon">
        <i class="fa-solid fa-circle-exclamation" />
      </div>
    </div>
    <!-- <button v-if="withClearButton && value.length" class="clear" @click="clear">
      <i class="icon-close"></i>
    </button> -->
    <div
      v-if="showError || showInnerError"
      class="invalid-feedback"
      :class="{ 'd-block': showError || showInnerError }"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textarea: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    customClass: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    },
    disableAutocapitalize: {
      type: Boolean,
      default: false
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoCheck: {
      type: Boolean,
      default: false
    }
    // withClearButton: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      innerValue: this.value,
      showError: false,
      showPassword: false,
      showInnerError: false
    };
  },
  methods: {
    clear() {
      this.$emit("input", "");
    },
    async blur() {
      // this.$emit("blur");
      if (!this.autoCheck) return;

      this.innerValue = this.innerValue + " ";
      this.innerValue = await this.innerValue.trim();

      if (!this.innerValue.length) {
        this.$emit("setError", ["Este campo é obrigatório."], this.id);
        this.showInnerError = true;
        return;
      } else if (this.type == "email") {
        let regExp = /^[\w-\.]+@([\w-]+\.)+[\w-]/;
        if (!this.innerValue.match(regExp)) {
          this.$emit("setError", ["Insira um email válido."], this.id);
          this.showInnerError = true;
          return;
        }
      } else if (this.type == "password" && this.innerValue.length < 6) {
        this.$emit(
          "setError",
          ["Certifique-se de que este campo tenha mais de 6 caracteres."],
          this.id
        );
        this.showInnerError = true;
        return;
      }

      this.$emit("setError", [""], this.id);
      this.showInnerError = false;
    }
  },
  computed: {
    classObject() {
      const result = { "is-invalid": this.showError || this.showInnerError };
      result[this.customClass] = true;
      return result;
    }
  },
  watch: {
    errors(newVal) {
      if (newVal && newVal.length) {
        this.showError = true;
      }
    },
    innerValue(newVal) {
      if (newVal != this.value) {
        this.showError = false;
        this.$emit("input", newVal);
      }
    },
    value(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  &.has-icon {
    .icon {
      position: absolute;
      line-height: 38px;
      left: 4px;
    }

    .form-control {
      padding-left: 32px;
    }
  }
  .form-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .form-control {
      padding-right: 7px;
    }
    .show-password-icon {
      position: absolute;
      right: 25px;
      top: 45px;
      font-size: 17px;
      cursor: pointer;
      user-select: none;
    }
    .show-error-icon {
      position: absolute;
      right: 25px;
      top: 47px;
      font-size: 17px;
      user-select: none;
      i {
        color: #ff3f34;
      }
    }
    .clear-button {
      background-color: transparent;
      padding: 0px;
      margin: 0px;
      border: 0px;
    }
  }
  .clear {
    position: absolute;
    height: 38px;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }
}
</style>
