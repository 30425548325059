<template>
  <b-form-checkbox
    switch
    v-model="value"
    class="theme-toggle"
    :class="$store.state.theme"
    @change="changeTheme"
  >
    Modo {{ value ? "escuro" : "claro" }}
  </b-form-checkbox>
</template>
<script>
export default {
  data() {
    return {
      value: this.$store.state.theme === "light"
    };
  },
  methods: {
    changeTheme(value) {
      let theme = value ? "light" : "dark";
      this.$store.commit("setTheme", theme);
      this.$store.dispatch("user/update", { theme });
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-toggle {
  ::v-deep {
    .custom-control-label::before {
      border: none;
    }
  }
  &.dark {
    ::v-deep {
      .custom-control-label {
        color: #bebfc7;

        &::before {
          background: radial-gradient(
            138.96% 141.42% at 100% 0%,
            rgba(83, 69, 104, 0.5) 0%,
            rgba(67, 56, 85, 0.5) 20%,
            rgba(52, 44, 67, 0.5) 40%,
            rgba(36, 31, 48, 0.5) 60%,
            rgba(21, 19, 30, 0.5) 80%,
            rgba(5, 6, 11, 0.5) 100%
          );
          box-shadow: 0px 0px 1px 2px #342c43 inset;
        }

        &::after {
          display: flex;
          font-size: 8px;
          content: "\f185";
          font-weight: 900;
          color: #342c46;
          align-items: center;
          justify-content: center;
          background-color: white;
          font-family: "Font Awesome 6 Free";
        }
      }
    }
  }

  &.light {
    ::v-deep {
      .custom-control-label {
        &::before {
          background: rgba(223, 224, 232, 0.8);
          box-shadow: 0.5px 2px 1px -1px rgba(168, 168, 168, 0.4) inset,
            2px 1px 3px 0px rgba(0, 0, 0, 0.05) inset,
            -1px -1px 2px 0px #dddee6 inset,
            -0.5px -0.5px 1.5px 0px rgba(248, 247, 247, 0.5) inset;
        }

        &::after {
          display: flex;
          font-size: 8px;
          color: white;
          content: "\f186";
          font-weight: 900;
          align-items: center;
          justify-content: center;
          background-color: black !important;
          font-family: "Font Awesome 6 Free";
        }
      }
    }
  }
}
</style>
