import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/icomoon/style.css";
import "./assets/scss/app.scss";
import "./components";
import "./plugins";
import { init } from "./setup/api";

Vue.use(BootstrapVue);
Vue.use(LottieVuePlayer);

init();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
