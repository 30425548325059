import { removeToken } from "../setup/api";

export default {
  logout(context) {
    return new Promise(resolve => {
      removeToken();
      context.commit("user/reset");
      resolve();
    });
  },

  getCourses(context, params) {
    const queryParams = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      return window.axios
        .get(`courses/acquired?${queryParams}&limit=50`)
        .then(({ data }) => {
          context.commit("setMyCourses", data.results);
          resolve(data);
        })
        .catch(reject);
    });
  },

  getNotes(context, params) {
    const queryParams = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      return window.axios
        .get(`modules/notes?${queryParams}`)
        .then(resolve)
        .catch(reject);
    });
  }
};
