<template>
  <div class="view-home container-fluid">
    <h6 class="font-weight-normal">
      {{ greeting }}
    </h6>
    <small class="text-secondary">Escolha seu curso</small>

    <div class="d-flex justify-content-center my-5" v-if="loading">
      <b-spinner variant="primary" style="width:48px; height: 48px;" />
    </div>

    <div class="row mt-4" v-else>
      <div
        v-for="(course, i) in myCourses"
        :key="i"
        class="col-lg-3 col-md-4 col-6 py-3"
      >
        <button
          class="position-relative"
          @click="goToCourse(course)"
          :disabled="isCanceled(course)"
        >
          <div class="img" :style="courseBackground(course)" />
          <div class="overlay px-3 py-4">
            <template v-if="isCanceled(course)">
              <i class="fa-solid fa-lock" />
              <small class="pt-3" v-if="course.canceled_at">
                (Cancelado em
                {{ new Date(course.canceled_at).toLocaleDateString("pt-Br") }})
              </small>
            </template>
          </div>
        </button>
        <span>
          {{ courseName(course) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/setup/api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState(["myCourses"]),
    greeting() {
      let greeting = "Bem vindo";

      const name = this.$user?.user?.name;
      if (name) greeting += ", " + name;

      greeting += "!";

      return greeting;
    }
  },
  methods: {
    goToCourse(course) {
      const courseUrl = course?.member_config?.url_site;
      if (!this.isCanceled(course) && courseUrl) {
        window.open(`${courseUrl}/token?token=${getToken()}`);
      }
    },
    isCanceled(course) {
      return !course.is_enable;
    },
    courseName(course) {
      return course?.member_config?.name;
    },
    courseBackground(course) {
      let backgroundImage =
        course?.member_config?.cover_blitz_club || course?.member_config?.brand;

      if (backgroundImage)
        return {
          backgroundImage: `url(${backgroundImage})`
        };
      return {};
    }
  },
  mounted() {
    this.loading = true;
    let params = this.$route.query.s ? { search: this.$route.query.s } : {};
    this.$store
      .dispatch("getCourses", params)
      .finally(() => (this.loading = false));
  }
};
</script>

<style lang="scss" scoped>
.view-home {
  h6 {
    margin: 0;
    font-size: 18px;
  }

  button {
    padding: 0;
    width: 100%;
    border: none;
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 4px;
    transition: 0.2s all;
    background: transparent;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);

    .img {
      height: 100%;
      border-radius: 5px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
    }

    .overlay {
      inset: 0;
      display: flex;
      color: white;
      position: absolute;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: linear-gradient(
        0deg,
        rgba(15, 15, 15, 0.9) 0%,
        rgba(15, 15, 15, 0) 100%
      );
      i {
        font-size: 30px;
      }
    }

    &:hover {
      opacity: 0.8;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      opacity: 0.4;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
</style>
