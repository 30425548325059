<template>
  <div class="template-auth">
    <video v-if="desktop" autoplay loop muted class="background-video">
      <source src="@/assets/auth/login-bg.mp4" type="video/mp4" />
    </video>

    <lottie-vue-player
      v-else
      :src="bgMobile"
      class="background-video d-md-none"
      autoplay
      loop
      :player-controls="false"
    />

    <!-- <div class="slot-wrapper" @mouseenter="animate"> -->
    <div class="slot-wrapper">
      <div class="header-desktop justify-content-start">
        <div class="position-relative">
          <img src="@/assets/auth/logo.svg" />
          <!-- <lottie-vue-player
            ref="logo"
            :src="logo"
            class="mr-4"
            :speed="0.7"
            :player-controls="false"
            style="width: 102.2%; background: transparent; position: absolute; left: -1.7%; top: -10%;"
          /> -->
        </div>
        <div class="divider" />
        <span>Escalando seu conhecimento</span>
      </div>

      <div class="header-mobile d-flex justify-content-center">
        <div class="position-relative">
          <!-- <lottie-vue-player
            ref="logoMobile"
            :src="logo"
            class="mr-4"
            :speed="0.7"
            :player-controls="false"
            style="width: 102.2%; background: transparent; position: absolute; left: -1.6%; top: -31.75%;"
          /> -->
          <img src="@/assets/auth/logo.svg" />
          <div class="d-flex justify-content-center">
            Escalando seu conhecimento
          </div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desktop: undefined
    };
  },
  computed: {
    logo() {
      return JSON.stringify(require("@/assets/auth/logo.json"));
    },
    bgMobile() {
      return JSON.stringify(require("@/assets/auth/login-bg-mobile.json"));
    }
  },
  methods: {
    // animate() {
    //   requestAnimationFrame(this.$refs.logo.togglePlayPause);
    // },
    setView() {
      if (window.innerWidth > 767) this.desktop = true;
      else this.desktop = false;
    }
  },
  mounted() {
    this.setView();
    window.addEventListener("resize", this.setView);
    // if (window.innerWidth < 992) {
    //   setTimeout(this.$refs.logoMobile.togglePlayPause, 1200);
    // }
  }
};
</script>

<style lang="scss" scoped>
.template-auth {
  min-height: 100vh;
  display: flex;
  overflow: hidden;

  .background-video {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: -10;
    width: 100vh;
    @media screen and (min-width: 768px) {
      height: 100%;
      width: 177.77777778vh; // 100 * 16/9
      min-width: 100%;
      min-height: 56.25vw; // 100 * 9/16
    }
  }

  .slot-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-inline: 32px;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(
      180deg,
      #2f2b3b 0%,
      rgba(47, 43, 59, 0.32) 100.91%
    );
    backdrop-filter: blur(10px);
    animation: fadeIn 1.5s ease-in-out both;
    -webkit-animation: fadeIn 1.5s ease-in-out both;

    @media screen and (min-width: 768px) {
      width: 409px;
    }
    @media screen and (min-width: 1024px) {
      width: 497px;
    }
    @media screen and (min-width: 1280px) {
      padding-inline: 56px;
      width: 540px;
    }
    @media screen and (min-width: 1536px) {
      padding-inline: 56px;
      width: 674px;
    }

    .header-desktop {
      position: relative;
      display: none;
      margin-bottom: 40px;
      font-size: 20px;
      color: var(--bw-1000, #fff);

      img {
        opacity: 0.8;
        width: 100px;
      }
      span {
        position: absolute;
        left: 91px;
        top: 14px;

        margin-left: 35px;
      }
      @media screen and (min-width: 1024px) {
        display: flex;
      }
      .divider {
        position: absolute;
        left: 91px;
        top: 15px;
        height: 27px;
        margin-inline: 24px;
        border-left: 1px solid var(--std-neut-x-400, #9d9d9d);
      }
    }
    .header-mobile {
      text-align: center;
      margin-bottom: 40px;
      color: var(--bw-1000, #fff);
      img {
        opacity: 0.8;
      }
      div {
        margin-top: 12px;
      }
      @media screen and (min-width: 1024px) {
        display: none !important;
      }
    }
  }

  ::v-deep {
    .form-control {
      min-height: 48px;
      height: 48px;
    }

    a {
      color: #fff;
      &.main:hover {
        font-weight: 500;
      }
    }

    p {
      color: var(--semantic-neutral-800, #e1e1e1);
      font-size: 20px;
    }

    .form-group {
      .form-control {
        font-size: 14px;
        color: var(--semantic-neutral-600, #c7c7c7);
        &:focus {
          box-shadow: none;
        }

        &.is-invalid:focus {
          box-shadow: 0 0 0 2px #ff3f34;
        }
      }
      .icon-eye,
      .icon-eye-off {
        color: #aaa9ae !important;
      }
      .show-password-icon {
        top: 42px !important;
      }
      .show-error-icon {
        top: 44px !important;
      }
      &.has-icon {
        .icon {
          line-height: 114px;
          left: 21px;
          color: #aaa9ae;
        }

        input {
          padding-left: 48px;
        }
      }
      label {
        color: var(--semantic-neutral-600, #c7c7c7);
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 4px;
      }
      // form-field border
      label::before {
        content: "";
        top: 30px;
        left: -2px;
        opacity: 0;
        height: 52px;
        width: calc(100% + 4px);
        border-radius: 8px;
        z-index: -1;
        position: absolute;
        // transition: 0.2s opacity;

        background: repeating-linear-gradient(180deg, #501b85, #140820),
          linear-gradient(180deg, #020202, #795c95),
          linear-gradient(90deg, #501b85 40%, #fff 63%, #501b85, #020202),
          linear-gradient(
            90deg,
            #020202,
            #8c2fea 60%,
            #b398cf 80%,
            #b398cf 90%,
            #795c95 98%,
            #795c95
          );
        background-size: 6px 100%, 6px 100%, 100% 6px, 100% 6px;
        background-position: 0 0, 100% 0, 0 0, 0 100%;
        background-repeat: no-repeat;
      }

      input {
        background: transparent;
        border: 1px solid var(--semantic-neutral-400, #939d9d);
        transition: 0.0001s;
        &.is-invalid {
          border: 2px solid #ff3f34;
        }

        &::placeholder {
          color: var(--semantic-neutral-500, #b4b4b4);
        }
      }

      &:focus-within {
        &:not(:has(div.show-error-icon)) {
          label::before {
            opacity: 1;
          }
        }
        input {
          background: #020202;
          border: 1px solid transparent !important;
          &::placeholder {
            color: var(--semantic-neutral-500, #b4b4b4);
          }
        }
      }
    }
  }
}
</style>
