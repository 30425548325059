<template>
  <div class="template-default d-flex flex-column" :class="theme">
    <action-dispatcher action="user/get" class="flex-fill" @error="errorUser">
      <header class="px-4 d-flex align-items-center">
        <div class="flex-fill"></div>

        <router-link :to="{ name: 'home' }" class="brand mr-5">
          <img :src="logo" />
        </router-link>

        <!-- <search /> -->
        <profile-dropdown @logout="logoutDialog = true" />
      </header>

      <confirmation
        @confirm="logout"
        v-model="logoutDialog"
        text="Tem certeza que deseja sair?"
        confirm-button-text="Sair"
      />

      <main class="flex-fill">
        <div class="position-relative slot-wrapper">
          <slot />
        </div>
      </main>
    </action-dispatcher>

    <app-footer />
  </div>
</template>

<script>
import ProfileDropdown from "@/components/templates/ProfileDropdown";
import AppFooter from "@/components/templates/AppFooter";
// import Search from "@/components/templates/Search.vue";

export default {
  components: {
    ProfileDropdown,
    AppFooter
    // Search
  },
  data() {
    return {
      logoutDialog: false
    };
  },
  computed: {
    theme() {
      return `theme-${this.$store.state.theme}`;
    },
    logo() {
      return require(this.theme === "theme-dark"
        ? `@/assets/images/club-logo-dark.svg`
        : `@/assets/images/club-logo.svg`);
    }
  },
  methods: {
    errorUser() {
      this.logout();
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.template-default {
  min-height: 100vh;

  i {
    font-size: 24px;
    min-width: 46px;
  }

  main {
    width: 100%;
    margin-top: 32px;
    position: relative;
  }

  header {
    min-height: 72px;
    max-height: 72px;

    .brand {
      top: 24px;
      left: 32px;
      position: absolute;

      img {
        max-height: 30px;
      }
    }
  }

  .slot-wrapper {
    transition: 0.3s padding ease;
  }
}
</style>
