<template>
  <div id="app">
    <component :is="template">
      <router-view :key="$route.fullPath" />
    </component>

    <snackbar />
  </div>
</template>

<script>
import { getToken } from "./setup/api";

export default {
  computed: {
    template() {
      return this.$route.meta.template;
    },
    isLogged() {
      return this.$store.state.user.logged;
    },
    showRouterView() {
      return !this.$route.meta.protected || this.isLogged;
    }
  },
  beforeMount() {
    if (!getToken() && this.$route.meta.protected) {
      this.$router.replace({ name: "login" });
    } else if (
      getToken() &&
      ["/login", "/register"].includes(document.location.pathname)
    ) {
      this.$router.replace({ name: "home" });
    }
  },
  watch: {
    $route: function() {
      if (!this.isLogged && this.$route.meta.protected) {
        this.$router.replace({ name: "login" });
      } else if (this.$user.user && !this.$user.user.is_enable) {
        this.$router.replace({ name: "sale" });
      }
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  overflow: hidden;
}
</style>
