export default {
  getModules(context, productId) {
    return new Promise((resolve, reject) => {
      return window.axios
        .get(`/courses/modules/list/${productId}?limit=1000&status=published`)
        .then(({ data: { results } }) => resolve(results))
        .catch(reject);
    });
  },
  getLessons(context, moduleId) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/modules/lessons/u/list/${moduleId}?status=published`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  getNotes(context, lessonId) {
    return new Promise((resolve, reject) => {
      return window.axios
        .get(`modules/notes?lesson_id=${lessonId}`)
        .then(({ data: { results } }) => {
          resolve(results);
        })
        .catch(reject);
    });
  }
};
