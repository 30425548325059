export default {
  setUser(state, user) {
    state.user = user;
  },
  updateUser(state, data) {
    for (const i in data) {
      state.user[i] = data[i];
    }
  },
  setLogged(state, logged) {
    state.logged = logged;
  },
  setMessages(state, messages) {
    state.messages = messages;
  },
  reset(state) {
    state.user = null;
    state.logged = false;
  }
};
